<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-0 "
        :style="{
          backgroundColor: '#e3eff7',
        }"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center h-100"
          :style="{
            backgroundImage: 'url(' + imgUrl + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-link
            class=""
            to="/"
          >
            <vuexy-logo
              class="my-2"
              style="width: 250px"
            />
          </b-link>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Знание начинается здесь 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Сделайте ваше обучение легким и увлекательным!
          </b-card-text>
          <b-overlay
            :show="showOverlay"
            rounded="sm"
            variant="white"
            opacity="0.8"
            blur="none"
            @shown="onShownOverlay"
            @hidden="onHiddenOverlay"
          >
            <form-wizard
              color="#0B63AC"
              :title="null"
              :subtitle="null"
              shape="square"
              finish-button-text="Регистрация"
              back-button-text="Предыдущий"
              next-button-text="Следующий"
              class="mb-3 box-shadow-0 border-primary "
              @on-complete="formSubmitted"
            >
              <!-- user details tab -->
              <tab-content
                title="Сведения о пользователе"
                :before-change="validationForm"
              >
                <h6 class="">
                  Помеченные знаком <span class="text-danger">*</span> обязательное поле для заполнения
                </h6>
                <!-- form -->
                <validation-observer ref="userInfoForm">
                  <b-form class="auth-register-form mt-2">
                    <!-- ФИО -->
                    <b-form-group
                      label-for="register-fio"
                    >
                      <template slot="label">
                        ФИО <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="ФИО"
                        rules="required"
                      >
                        <b-form-input
                          id="register-fio"
                          v-model="userInfo.fio"
                          name="register-fio"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- phone -->
                    <b-form-group
                      label-for="register-phone"
                    >
                      <template slot="label">
                        Контактный телефон <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="контактный телефон"
                        rules="required"
                      >
                        <b-form-input
                          id="register-phone"
                          v-model="userInfo.phone"
                          name="register-phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- email -->
                    <b-form-group
                      label-for="register-email"
                    >
                      <template slot="label">
                        Электронная почта <span class="text-danger">*</span>
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        name="электронная почта"
                        rules="required|email"
                      >
                        <b-form-input
                          id="register-email"
                          v-model="userInfo.userEmail"
                          name="register-email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="info@example.tj"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Год рождения -->
                    <b-form-group
                      label-for="register-birth-year"
                    >
                      <template slot="label">
                        Год рождения <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="год рождения"
                        rules="required"
                      >
                        <v-select
                          v-model="userInfo.birthYear"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userInfo.birthYearOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="register-birth-year"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Пол -->
                    <b-form-group
                      label-for="register-sex"
                    >
                      <template slot="label">
                        Пол <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="пол"
                        rules="required"
                      >
                        <b-form-radio-group
                          v-model="userInfo.sex"
                          :options="userInfo.sexOptions"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Адрес -->
                    <b-form-group
                      label-for="register-address"
                    >
                      <template slot="label">
                        Адрес <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="адрес"
                        rules="required"
                      >
                        <b-form-input
                          id="register-address"
                          v-model="userInfo.address"
                          name="register-address"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Основная деятельность -->
                    <b-form-group
                      label-for="register-main-activity"
                    >
                      <template slot="label">
                        Основная деятельность <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="основная деятельность"
                        rules="required"
                      >
                        <v-select
                          v-model="userInfo.mainActivity"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userInfo.mainActivityOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="main-activity"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- У Вас есть организация? -->
                    <b-form-group
                      label-for="register-status-organization"
                    >
                      <template slot="label">
                        У Вас есть организация? <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="У Вас есть организация?"
                        rules="required"
                      >
                        <b-form-radio-group
                          v-model="userInfo.statusOrganization"
                          :options="userInfo.statusOrganizationOptions"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-form>
                </validation-observer>
              </tab-content>

              <!-- user company details tab -->
              <tab-content
                v-if="userInfo.statusOrganization === 'Yes'"
                title="Информация о компании"
                :before-change="validationCompanyDetailsForm"
              >
                <h6 class="">Помеченные знаком <span class="text-danger">*</span> обязательное поле для
                  заполнения</h6>
                <!-- form -->
                <validation-observer ref="companyDetailsForm">
                  <!-- Наименование компании -->
                  <b-form-group
                    label="Наименование компании"
                    label-for="register-company-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="наименование компании"
                    >
                      <b-form-input
                        id="register-company-name"
                        v-model="company.name"
                        name="register-company-name"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Ссылка на сайт компании URL -->
                  <b-form-group
                    label="Ссылка на сайт компании URL"
                    label-for="register-company-site"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ссылка на сайт компании URL"
                    >
                      <b-form-input
                        id="register-company-site"
                        v-model="company.site"
                        name="register-company-site"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Кратко опишите свой проект (до 200 слов) -->
                  <b-form-group
                    label="Кратко опишите свой проект (до 200 слов)"
                    label-for="register-company-about"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="кратко опишите свой проект (до 200 слов)"
                      rules="max:200"
                    >
                      <b-form-textarea
                        id="register-company-about"
                        v-model="company.about"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Какую проблему решает ваш проект? -->
                  <b-form-group
                    label="Какую проблему решает ваш проект?"
                    label-for="register-company-resolve-problems"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="какую проблему решает ваш проект?"
                    >
                      <b-form-textarea
                        id="register-company-resolve-problems"
                        v-model="company.resolveProblems"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Загрузите презентацию  -->
                  <validation-provider
                    #default="{ errors }"
                    name="загрузите презентацию "
                  >
                    <b-form-group
                      label="Загрузите презентацию "
                      label-for="file"
                    >
                      <b-form-file
                        id="presentation"
                        v-model="company.presentation"
                        browse-text="Обзор..."
                        accept="application/pdf, .docx, .doc, .ppt, .pptx, .mp3, .mp4, .avi, .mpeg, .divx"
                        placeholder="Выберите файл или перетащите его сюда..."
                        drop-placeholder="Скиньте файл сюда..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- К какой сфере относится ваш стартап? -->
                  <b-form-group
                    label="К какой сфере относится ваш стартап?"
                    label-for="register-main-company-type-work"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="к какой сфере относится ваш стартап?"
                    >
                      <v-select
                        v-model="company.typeWork"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="company.typeWorkOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="main-company-type-work"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- На какой стадии находится ваш стартап? -->
                  <b-form-group
                    label="На какой стадии находится ваш стартап?"
                    label-for="register-main-company-stages"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="на какой стадии находится ваш стартап?"
                    >
                      <v-select
                        v-model="company.stage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="company.stageOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="main-company-stages"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Направление деятельности вашего стартапа: -->
                  <b-form-group
                    label-for="register-company-direction-activity"
                  >
                    <template slot="label">Направление деятельности вашего стартапа: <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="направление деятельности вашего стартапа"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="company.directionActivity"
                        :options="company.directionActivityOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- В какой сумме хотите получить инвестиции? -->
                  <b-form-group
                    label="В какой сумме хотите получить инвестиции?"
                    label-for="register-company-sum-invest"
                  >
                    <b-input-group>

                      <b-input-group-prepend is-text>
                        от
                      </b-input-group-prepend>
                      <validation-provider
                        #default="{ errors }"
                        name="от"
                      >
                        <b-form-input
                          id="register-company-sum-invest"
                          v-model="company.sumInvest.from"
                          type="number"
                          name="register-company-sum-invest"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-input-group-prepend is-text>
                        до
                      </b-input-group-prepend>
                      <validation-provider
                        #default="{ errors }"
                        name="до"
                      >
                        <b-form-input
                          id="register-company-sum-invest"
                          v-model="company.sumInvest.to"
                          type="number"
                          name="register-company-sum-invest"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-input-group>
                  </b-form-group>

                  <!-- Для чего Вам нужна эта сумма? -->
                  <b-form-group
                    label="Для чего Вам нужна эта сумма?"
                    label-for="register-company-sum-invest-for"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="для чего Вам нужна эта сумма?"
                    >
                      <b-form-textarea
                        id="register-company-sum-invest-for"
                        v-model="company.sumInvestFor"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Вы зарегистрировали свою компанию? -->
                  <b-form-group
                    label-for="register-company-status-register"
                  >
                    <template slot="label">Вы зарегистрировали свою компанию? <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="вы зарегистрировали свою компанию"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="company.statusRegister"
                        :options="company.statusRegisterOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- divider -->
                  <div class="divider my-2 divider-left">
                    <div class="divider-text">
                      <h5 class="">
                        Дополнительные вопросы:
                      </h5>
                    </div>
                  </div>

                  <!-- Какое ваше самое впечатляющее достижение? -->
                  <b-form-group
                    label="Какое ваше самое впечатляющее достижение?"
                    label-for="register-company-achievement"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="какое ваше самое впечатляющее достижение?"
                    >
                      <b-form-textarea
                        id="register-company-achievement"
                        v-model="company.achievement"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Вы технический? -->
                  <b-form-group
                    label-for="register-company-technical"
                  >
                    <template slot="label">
                      Вы технический? <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="вы технический?"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="company.statusTechnical"
                        :options="company.statusTechnicalOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Вы активно ищете соучредителя? -->
                  <b-form-group
                    label-for="register-company-technical"
                  >
                    <template slot="label">
                      Вы активно ищете соучредителя? <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="вы активно ищете соучредителя?"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="company.statusFind"
                        :options="company.statusFindOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Вы активно работаете над стартапом?  -->
                  <b-form-group
                    label-for="register-company-work-startup"
                  >
                    <template slot="label">Вы активно работаете над стартапом? <span class="text-danger">*</span>
                    </template>
                    <small>«Активно» означает 10+ часов в неделю (а лучше 20+). Вы можете вернуться и изменить это в
                      любое
                      время.</small>
                    <validation-provider
                      #default="{ errors }"
                      name="вы активно работаете над стартапом? "
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="company.statusWorkStartup"
                        :options="company.statusWorkStartupOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </validation-observer>
              </tab-content>

              <!-- sign in details tab -->
              <tab-content
                title="Информация о входе"
                :before-change="validationSignInDetailsForm"
              >
                <!-- form -->
                <validation-observer ref="signInDetailsForm">
                  <!-- Username -->
                  <b-form-group
                    label="Логин"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="логин"
                      rules="required"
                    >
                      <b-form-input
                        id="register-username"
                        v-model="userInfo.userEmail"
                        name="register-username"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- password -->
                  <b-form-group
                    label-for="register-password"
                    label="Пароль"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="пароль"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="password"
                          v-model="signinInfo.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- confirmed password -->
                  <b-form-group
                    label-for="register-confirm-password"
                    label="Подтверждение пароль"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="подтверждение пароль"
                      rules="required|confirmed:password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-confirm-password"
                          v-model="signinInfo.confirmPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="register-confirm-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="политика конфиденциальности"
                      rules="required"
                    >
                      <b-form-checkbox
                        id="register-privacy-policy"
                        v-model="signinInfo.status"
                        name="checkbox-1"
                        :state="errors.length > 0 ? false:null"
                      >
                        Я согласен с
                        <b-link v-b-modal.modal-privacy-policy>
                          политикой конфиденциальности и условиями
                        </b-link>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </validation-observer>
              </tab-content>
            </form-wizard>
            <template #overlay>
              <div class="text-center">
                <feather-icon
                  icon="UploadCloudIcon"
                  class="text-primary mr-1"
                  size="60"
                />
                <div class="mb-3">
                  Пожалуйста подождите...
                </div>
                <b-progress
                  min="0"
                  max="100"
                  :value="uploadPercentage"
                  variant="success"
                  height="3px"
                  class="mx-n4 rounded-0 mb-1"
                />
                <b-button
                  ref="cancel"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  aria-describedby="cancel-label"
                  @click="showOverlay = false"
                >
                  Отменить
                </b-button>
              </div>
            </template>
          </b-overlay>
          <p class="text-center mt-2">
            <span>У вас уже есть учетная запись?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Войдите в систему</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <b-modal
      id="modal-privacy-policy"
      title="Политикой конфиденциальности и условиями"
      ok-title="Принимаю"
      size="lg"
      cancel-title="Не принимаю"
      @ok="signinInfo.status = true"
      @cancel="signinInfo.status = false"
    >
      <b-card-text>
        <div class="">
          <h1 id="privacy">
            Политика конфиденциальности
          </h1>
          <p>
            <i>Данная Политика конфиденциальности была последний раз обновлена Декабрь 1, 2021.</i>
          </p>
          <p>
            Благодарим Вас за то, что присоединились к STARTUP CHOIKHONA. Мы в STARTUP CHOIKHONA (далее <strong>«STARTUP
              CHOIKHONA»</strong>,
            <strong>«мы»</strong>, <strong>«нас»</strong>) уважаем конфиденциальность клиентов и хотим, чтобы Вы
            понимали, как мы будем собирать, использовать и передавать данные о Вас. Эта Политика конфиденциальности
            описывает нашу практику сбора данных и Ваши права в отношении персональных данных.
          </p>
          <p>
            Если мы не приводим ссылку на другую политику или не указываем иное, настоящая Политика конфиденциальности
            применяется при посещении и использовании Вами веб-сайтов STARTUP CHOIKHONA и CorpU, мобильных приложений,
            API или
            связанных услуг, включая CorpU Open (<strong>«Услуги»</strong>). Это также применимо к потенциальным
            клиентам наших корпоративных и бизнес-продуктов.
          </p>
          <p>
            <strong>Используя Услуги, Вы соглашаетесь с условиями настоящей Политики конфиденциальности.</strong> Вы не
            должны использовать Услуги, если Вы не согласны с настоящей Политикой конфиденциальности или любым другим
            соглашением, которое регламентирует использование Услуг.
          </p>
          <h2>
            Оглавление
          </h2>
          <ul>
            <li><a href="#section1">
              1. Какие данные мы собираем
            </a></li>
            <li><a href="#section2">
              2. Каким образом мы собираем данные о Вас
            </a></li>
            <li><a href="#section3">
              3. В каких целях мы используем Ваши данные
            </a></li>
            <li><a href="#section4">
              4. Кому мы передаем Ваши данные
            </a></li>
            <li><a href="#section5">
              5. Безопасность
            </a></li>
            <li><a href="#section6">
              6. Ваши права
            </a></li>
            <li><a href="#section7">
              7. Правила, применимые к определенным юрисдикциям
            </a></li>
            <li><a href="#section8">
              8. Обновления и контактные данные
            </a></li>
          </ul>
          <h2 id="section1">
            1. Какие данные мы собираем
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Мы осуществляем сбор определенных данных, полученных непосредственно от Вас, например данных, которые Вы
              сообщаете нам, данных о Вашем изучении материалов и данных со сторонних платформ, которые Вы связываете с
              STARTUP CHOIKHONA. Мы также собираем некоторые данные автоматически, например информацию о Вашем
              устройстве и о том, с
              какими нашими Услугами Вы взаимодействуете или какие Услуги Вы используете.
            </div>
          </div>
          <p />
          <h3>
            1.1. Данные, которые Вы предоставляете нам
          </h3>
          <p>
            Мы можем собирать различные данные о Вас в зависимости от того, как Вы пользуетесь Услугами. Ниже приведены
            некоторые примеры, которые помогут Вам лучше понять, какие данные мы собираем.
          </p>
          <p>
            Когда Вы создаете учетную запись и используете Услуги (в том числе через стороннюю платформу), мы собираем
            любые данные, которые Вы предоставляете нам напрямую, в том числе:
          </p>
          <p />
          <table class="table table-bordered table-condensed">
            <tbody>
              <tr>
                <td class="table-row-description">
                  <strong>Данные учетной записи</strong>
                </td>
                <td>
                  Для использования определенных функций (таких как доступ к материалам) необходимо создать учетную запись
                  пользователя, для которой нам необходимо собрать и сохранить информацию о вашем адресе электронной
                  почты, пароль и настройки учетной записи. Для создания учетной записи преподавателя мы собираем и храним
                  информацию о вашем адресе электронной почты, имени, пароле и настройках учетной записи. При
                  использовании определенных функций веб-сайта Вас попросят предоставить дополнительную информацию, такую
                  как род занятий, номер документа, удостоверяющего личность, фото для подтверждения, дата рождения,
                  расу/национальность, особые навыки и интересы, а также номер телефона. После создания учетной записи Вам
                  будет присвоен уникальный идентификационный номер.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Данные профиля</strong>
                </td>
                <td>
                  Вы также можете предоставить данные профиля, например фотографию, заголовок, биографию, язык, ссылку на
                  веб-сайт, профили в социальных сетях, страну или другие данные. Ваши данные профиля будут доступны для
                  просмотра другим пользователям.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Общедоступные материалы</strong>
                </td>
                <td>
                  Некоторые Услуги позволяют Вам общаться с другими пользователями или делиться материалами публично, в
                  том числе путем публикации отзывов о материалах, вопросов или ответов на вопросы, отправки сообщений
                  студентам или преподавателям или публикации фотографий или других загруженных Вами материалов. Такие
                  общедоступные материалы могут быть доступны для просмотра другим пользователям в зависимости от того,
                  где они были опубликованы.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Данные об образовательных материалах</strong>
                </td>
                <td>
                  Когда Вы получаете доступ к материалам, мы собираем определенные данные, в том числе о том, какие курсы,
                  задания, лабораторные работы, рабочие пространства и тесты вы начали и завершили; приобретения и кредиты
                  в рамках контента; подписки; сертификаты об окончании; Ваше взаимодействие с преподавателями,
                  ассистентами преподавателей и другими студентами; а также эссе, ответы на вопросы и другие элементы,
                  предоставленные для удовлетворения требований курса и соответствующих материалов. Если Вы являетесь
                  преподавателем, то мы сохраним ваши образовательные материалы, которые могут содержать ваши данные.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Данные о Ваших учетных записях на других сервисах</strong>
                </td>
                <td>
                  <p>
                    Мы можем получить определенную информацию через Ваши учетные записи в социальных сетях или другие
                    учетные записи в сети, если они подключены к вашей учетной записи STARTUP CHOIKHONA. Если Вы зайдете
                    на STARTUP CHOIKHONA
                    через Facebook или через другую стороннюю платформу или сервис, мы просим у Вас разрешение на доступ к
                    определенной информации о такой другой учетной записи. Например, в зависимости от платформы или
                    сервиса мы можем собирать данные о Вашем имени, изображении профиля, идентификационном номере учетной
                    записи, адресе электронной почты для входа, местоположении, физическом местоположении Ваших устройств
                    доступа, гендерной принадлежности, дате рождения и списке друзей или контактов.
                  </p>
                  <p>
                    Эти платформы и сервисы предоставляют нам информацию через API. То, какую информацию мы получаем,
                    зависит от того, какую информацию Вы (с помощью настроек конфиденциальности) или платформа или сервис
                    решили предоставить нам.
                  </p>
                  <p>
                    Если Вы используете наши Услуги через стороннюю платформу или сервис или переходите по любой сторонней
                    ссылке, сбор, использование и распространение Ваших данных будет также регулироваться политиками
                    конфиденциальности и другими соглашениями таких третьих сторон.
                  </p>
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Розыгрыши, промоакции и опросы</strong>
                </td>
                <td>
                  Мы можем предложить Вам пройти опрос или поучаствовать в промоакции (например, конкурсе, розыгрыше или
                  соревновании) через Услуги или на сторонней платформе. Если Вы принимаете участие в такой промоакции, мы
                  будем собирать и хранить данные, предоставляемые Вами в рамках участия, такие как Ваше имя, адрес
                  электронной почты, почтовый адрес, дата рождения или номер телефона. На эти данные распространяется
                  действие настоящей Политики конфиденциальности, если иное не указано в официальных правилах промоакции
                  или в другой политике конфиденциальности. Собранные данные будут использоваться для проведения
                  промоакции или опроса, в том числе для уведомления победителей и распределения вознаграждений. Чтобы
                  получить вознаграждение, Вам может потребоваться предоставить нам возможность опубликовать некоторые
                  Ваши данные в открытых источниках (например, на странице победителей). Если мы используем стороннюю
                  платформу для проведения опроса или промоакции, будет применяться политика конфиденциальности третьей
                  стороны.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Сообщения и поддержка</strong>
                </td>
                <td>
                  Если Вы обратитесь к нам за поддержкой или сообщите о проблеме или беспокойстве (независимо от того,
                  создана ли уже у Вас учетная запись или нет), мы собираем и храним Ваши контактные данные, сообщения и
                  другие данные о Вас, такие как Ваше имя, адрес электронной почты, сообщения, местоположение,
                  идентификатор пользователя STARTUP CHOIKHONA, номер возвратной транзакции и любые другие данные, которые
                  Вы
                  предоставите нам или которые мы получаем с помощью автоматизированных средств (которые мы рассмотрим
                  ниже). Мы используем эти данные для ответа и рассмотрения Вашего вопроса или опасения в соответствии с
                  данной Политикой конфиденциальности.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          <p>
            Мы храним указанные Выше данные и связываем их с Вашей учетной записью.
          </p>
          <h3>
            1.2. Данные, собираемые нами с помощью автоматизированных средств
          </h3>
          <p>
            Когда Вы получаете доступ к разделу «Услуги» (в том числе просматриваете наши материалы), мы собираем
            определенные данные с помощью автоматизированных средств, в том числе:
          </p>
          <p />
          <table class="table table-bordered table-condensed">
            <tbody>
              <tr>
                <td class="table-row-description">
                  <strong>Системные данные</strong>
                </td>
                <td>
                  Технические данные о Вашем компьютере или устройстве, такие как Ваш IP-адрес, тип устройства, тип и
                  версия операционной системы, уникальный идентификатор устройства, браузер, язык браузера, домен и другие
                  системные данные, тип платформы.
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Данные об использовании</strong>
                </td>
                <td>
                  Статистика использования раздела «Услуги», в том числе изучаемые материалы, время, проведенное на
                  страницах или в разделе «Услуги», посещенные страницы, используемые функции, поисковые запросы, данные
                  нажатий, дата и время, реферал, а также другие данные о пользовании разделом «Услуги».
                </td>
              </tr>
              <tr>
                <td class="table-row-description">
                  <strong>Приблизительные географические данные</strong>
                </td>
                <td>
                  Приблизительное географическое местоположение, в том числе информация о стране, городе и географических
                  координатах, рассчитанных на основе Вашего IP-адреса.
                </td>
              </tr>
            </tbody>
          </table>
          <p />
          <p>
            Данные, перечисленные Выше, собираются с помощью лог-файлов серверов и технологий отслеживания, как указано
            в разделе "Файлы cookie и инструменты сбора данных" ниже. Мы храним эти данные и связываем их с Вашей
            учетной записью.
          </p>
          <h3>
            1.3 Данные от третьих сторон
          </h3>
          <p>
            Если Вы являетесь действующим или потенциальным корпоративным клиентом STARTUP CHOIKHONA Business, то помимо
            предоставленных данных мы имеем право собирать определенную контактную информацию о компании из сторонних
            коммерческих источников.
          </p>
          <h2 id="section2">
            2. Каким образом мы собираем данные о Вас
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Мы используем инструменты, такие как файлы cookie, веб-маяки и подобные средства для сбора данных,
              перечисленные выше. Некоторые из этих средств позволяют Вам отказаться от сбора данных.
            </div>
          </div>
          <p />
          <h3>
            2.1. Файлы cookie и инструменты сбора данных
          </h3>
          <p>
            Мы используем файлы cookie — небольшие текстовые файлы, которые сохраняются вашим браузером и могут
            использоваться для сбора, хранения и обмена данными о Ваших действиях на веб-сайтах, в том числе на сайте
            STARTUP CHOIKHONA. Файлы cookie также позволяют нам запоминать информацию о Ваших посещениях сайта STARTUP
            CHOIKHONA, например
            предпочитаемые Вами языковые настройки, и делать сайт удобнее в использовании. Чтобы узнать больше о файлах
            cookie, посетите сайт https://cookiepedia.co.uk/all-about-cookies. Мы также имеем право использовать
            пиксель-теги в наших электронных сообщениях, чтобы отслеживать их доставку и момент открытия сообщения.
          </p>
          <p>
            STARTUP CHOIKHONA и поставщики услуг, действующие от нашего имени (например, Google Analytics и сторонние
            рекламные
            агенты), используют лог-файлы сервера и автоматизированные инструменты сбора данных, такие как файлы cookie,
            теги, скрипты, персональные ссылки, сведения устройства или браузера об отпечатках пальцев и веб-маяки
            (вместе именуемые <strong>"Инструменты сбора данных"</strong>) при получении доступа и использовании Вами
            раздела "Услуги". Эти Инструменты сбора данных автоматически отслеживают и собирают определенные Системные
            данные и Данные об использовании (как указано в Разделе 1), когда Вы пользуетесь Услугами. В некоторых
            случаях мы связываем данные, собранные с помощью этих Инструментов сбора данных, с другими данными, которые
            мы собираем согласно настоящей Политике конфиденциальности.
          </p>
          <p />
          <h3>
            2.2 Для чего мы используем Инструменты сбора данных
          </h3>
          <p>
            STARTUP CHOIKHONA использует следующие виды Инструменты сбора данных для описанных ниже целей:
          </p>
          <p />
          <ul>
            <li type="circle">
              <u>Строго необходимые:</u> эти Инструменты сбора данных позволяют получать доступ к сайту, предоставляют
              набор базовых функций (например, вход и получение доступа к материалам), обеспечивают безопасность сайта и
              защиту от мошеннических входов, а также обнаруживают и предотвращают неправильное или не авторизованное
              использование вашей учетной записи. Данные инструменты необходимы для корректной работы раздела «Услуги»,
              поэтому если Вы их отключите, некоторые части сайта перестанут работать или станут недоступны.
            </li>
            <li type="circle">
              <u>Функциональные:</u> эти Инструменты сбора данных запоминают данные о браузере и ваших предпочтениях,
              обеспечивают работу дополнительных функций сайта, настраивают отображение наиболее подходящих для вас
              материалов и запоминают настройки, влияющие на внешний вид и поведение раздела "Услуги" (например,
              предпочитаемый язык или уровень громкости при воспроизведении видео).
            </li>
            <li type="circle">
              <u>Производительность:</u> эти Инструменты сбора данных помогают оценивать и улучшать раздел "Услуги",
              предоставляя данные об использовании и производительности, количестве посещений, источниках трафика, или о
              ресурсе, откуда было скачано приложение. Данные инструменты позволяют тестировать разные версии STARTUP
              CHOIKHONA,
              чтобы определить предпочитаемые пользователями функции и материалы и открываемые сообщения электронной
              почты.
            </li>
            <li type="circle">
              <u>Рекламные:</u> эти Инструменты сбора данных используются для показа актуальной рекламы (на нашем сайте
              и/или других сайтах) в соответствии с собранными данными об использовании и о системе (как указано в
              Разделе 1) и со сведениями, собранными о Вас поставщиками рекламы. Отображаемая реклама может быть
              подобрана в соответствии с Вашей недавней активностью или активностью за все время на других сайтах и
              сервисах. Для отображения таргетированной рекламы мы можем предоставлять поставщикам услуг хэшированную,
              анонимизированную версию Вашего адреса электронной почты (в нечитаемой для человека форме) и материалов, к
              которым Вы предоставили общий доступ через Услуги.
            </li>
            <li type="circle">
              <u>Социальные сети:</u> эти Инструменты сбора данных обеспечивают функции социальных сетей, например,
              обмен материалами с друзьями или сетями. Эти файлы cookie могут отслеживать активность пользователя или
              устройства на других сайтах для составления профиля интересов пользователя и отображения таргетированной
              рекламы.
            </li>
          </ul>
          <p />
          <p>
            Вы можете настроить веб-браузер на предупреждение Вас о попытках разместить файлы cookie на Вашем
            компьютере, ограничить типы файлов cookie, прием которых Вы разрешаете, или полностью отказаться от
            использования файлов cookie. Если Вы откажетесь от файлов cookie, Вы не сможете использовать некоторые или
            все функции Услуг, а Ваша навигация изменится или станет менее функциональной. Чтобы узнать больше об
            управлении Инструментами сбора данных, ознакомьтесь с Разделом 6.1 (Ваши решения об использовании Ваших
            данных) ниже.
          </p>
          <h2 id="section3">
            3. В каких целях мы используем Ваши данные
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Мы используем Ваши данные для предоставления наших Услуг, связи с Вами, устранения неполадок, защиты от
              мошенничества и злоупотреблений, улучшения и обновления наших Услуг, анализа использования наших Услуг,
              предоставления персонализированной рекламы, а также в других целях, предписанных законом или необходимых
              для обеспечения безопасности и целостности. Мы храним Ваши данные в течение того периода времени, когда
              эти данные необходимы для тех целей, для которых были собраны.
            </div>
          </div>
          <p />
          <p>
            Мы используем данные, полученные в ходе использования Вами Услуг, для следующих целей:
          </p>
          <p />
          <ul>
            <li>
              обеспечение и администрирование раздела «Услуги», включая содействие прохождению образовательного
              материала, выдачу сертификатов об окончании, персонализированное отображение контента и облегчение
              взаимодействия с другими пользователями;
            </li>
            <li>
              обработка выплат преподавателям и другим третьим сторонам;
            </li>
            <li>
              обработка Ваших запросов и заказов образовательных материалов, товаров, определенных услуг, информации или
              функций;
            </li>
            <li>
              общение с Вами относительно учетной записи:
              <ul>
                <li type="circle">
                  реагирование на Ваши вопросы или проблемы;
                </li>
                <li type="circle">
                  отправка Вам административных сообщений и информации, включая комментарии преподавателей, студентов
                  или ассистентов преподавателей, уведомления об изменении Услуг и обновлении наших соглашений;
                </li>
                <li type="circle">
                  информирование или отправка сообщений, например электронных писем или текстовых сообщений, о Вашей
                  успеваемости по изучению курсов, а также с информацией о соответствующих материалах, бонусных
                  программах, новых услугах и возможностях, промоакциях, информационных бюллетенях и других доступных
                  материалах от преподавателей (от которых можно отказаться в любое время);
                </li>
                <li type="circle">
                  отправка на Ваше беспроводное устройство push-уведомлений об обновлении и других соответствующих
                  сообщений (Вы можете управлять уведомлениями на страницах мобильного приложения "опции" или
                  "настройки");
                </li>
              </ul>
            </li>
            <li>
              Управление учетной записью и настройками учетной записи, а также персонализация;
            </li>
            <li>
              облегчение технического функционирования раздела "Услуги", включая устранение неполадок и решение проблем,
              обеспечение безопасности, а также предотвращение мошенничества и злоупотреблений;
            </li>
            <li>
              Проверка личности преподавателя;
            </li>
            <li>
              получение обратной связи от пользователей;
            </li>
            <li>
              рыночная продукция, услуги, опросы и промоакции;
            </li>
            <li>
              коммерческие планы подписки для потенциальных клиентов;
            </li>
            <li>
              получение более подробной информации о Вас посредством привязки Ваших данных к дополнительным данным
              сторонних поставщиков данных и/или анализа данных с помощью поставщиков услуг аналитики;
            </li>
            <li>
              идентификация уникальных пользователей различных устройств;
            </li>
            <li>
              адаптация рекламы на всех устройствах;
            </li>
            <li>
              улучшение раздела "Услуги", а также разработка новых продуктов, услуг и функций;
            </li>
            <li>
              анализ тенденций и трафика, отслеживание покупок и отслеживание данных об использовании;
            </li>
            <li>
              реклама Услуг на сторонних сайтах или в приложениях;
            </li>
            <li>
              соответствие требованиям или допускам закона; или
            </li>
            <li>
              обеспечение безопасности или неприкосновенности наших пользователей, сотрудников, третьих лиц,
              общественности или раздела "Услуги" способом, который мы сочтем необходимым.
            </li>
          </ul>
          <p />
          <h2 id="section4">
            4. Кому мы передаем Ваши данные
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Мы передаем определенные данные о Вас преподавателям, другим студентам, компаниям, оказывающим услуги для
              нас, аффилированным партнерам STARTUP CHOIKHONA, нашим деловым партнерам, поставщикам услуг аналитики и
              обогащения
              данных, поставщикам Ваших социальных сетей, компаниям, которые помогают нам проводить промоакции и опросы,
              а также рекламным компаниям, которые помогают нам рекламировать наши Услуги. Мы можем также передавать
              ваши данные по мере необходимости в целях безопасности, нормативно-правового соответствия или в ходе
              корпоративной реструктуризации. Наконец, мы можем передавать данные другими способами, если они
              представлены в обобщенной или анонимной форме, или если мы получим ваше согласие на передачу.
            </div>
          </div>
          <p />
          <p>
            Мы можем разглашать Ваши данные третьим лицам при следующих обстоятельствах или согласно описанному в данной
            Политике конфиденциальности:
          </p>
          <p />
          <ul>
            <li><u>Передача данных Вашим преподавателям:</u>
              Мы передаем данные о Вас, которыми мы располагаем (кроме Вашего адреса электронной почты), преподавателям
              или ассистентам преподавателей тех образовательных материалов, к которым вы получаете доступ или о которых
              запросили информацию, чтобы они могли совершенствовать свои материалы для Вас и других студентов. Эти
              данные могут включать в себя ваш город, страну, язык браузера, операционную систему, параметры устройства,
              сайт, с которого Вы перешли на STARTUP CHOIKHONA, а также Ваши действия на сайте STARTUP CHOIKHONA. Если
              мы собираем
              дополнительные данные о Вас (например, возраст или гендерную принадлежность), мы также можем передавать
              их. Мы не будем передавать Ваш адрес электронной почты преподавателям или ассистентам преподавателей.
            </li>
            <li><u>Передача данных другим студентам и преподавателям:</u>
              В зависимости от Ваших настроек опубликованные Вами материалы и данные профиля могут быть доступны для
              просмотра другим лицам, в том числе другим студентам и преподавателям. Если Вы задаете вопрос
              преподавателю или ассистенту, информация о Вас (в том числе Ваше имя) также может быть доступна. Обратите
              внимание, что на в CorpU Open все чаты, доски сообщений, новостные группы или другие общедоступные форумы
              доступны и видны другим участникам, поэтому публикация частной или конфиденциальной информации с помощью
              данных функций не рекомендуется.
            </li>
            <li><u>Передача данных поставщикам услуг, подрядчикам и агентам:</u>
              Мы передаем ваши данные сторонним компаниям, которые предоставляют услуги от нашего имени, например
              обработку платежей, противодействие мошенничеству и насилию, анализ данных, маркетинговые и рекламные
              услуги (включая перенаправленную рекламу), услуги электронной почты и хостинга, а также обслуживание и
              поддержку клиентов. Эти поставщики услуг могут получить доступ к Вашим персональным данным и обязаны
              использовать их по нашему указанию исключительно для предоставления запрошенных нами услуг.
            </li>
            <li><u>В отношении аффилированных партнеров STARTUP CHOIKHONA:</u>
              Мы можем передавать Ваши данные в рамках нашей корпоративной группы компаний, которые связаны отношениями
              совместного владения и управления, необходимыми для реализации и поддержки предоставляемых нами Услуг.
            </li>
            <li><u>Передача данных деловым партнерам:</u>
              у нас есть соглашения с другими веб-сайтами и платформами для распространения наших Услуг и привлечения
              пользователей на сайт STARTUP CHOIKHONA. Например, мы работаем с компанией Benesse в Японии. В зависимости
              от Вашего
              местоположения мы можем передавать Ваши данные таким партнерам.
            </li>
            <li><u>С организациями, предоставляющими кредиты на дополнительное образование.</u>
              Если Вы выбрали курс для получения дополнительного профессионального образования, Мы вправе предоставить
              данную информацию по запросу от организации, выдавшей кредит на получение дополнительного образования.
            </li>
            <li><u>Передача данных сервисам по анализу и обогащению данных:</u>
              в рамках использования сторонних инструментов аналитики, таких как Google Analytics, и сервисов по
              обогащению данных, таких как ZoomInfo, мы передаем по мере необходимости определенную контактную
              информацию, Данные учетной записи, Системные данные, Данные об использовании (как указано в Разделе 1) или
              обезличенные данные. Обезличенные данные — это данные, из которых мы удалили такие элементы, как Ваше имя
              и адрес электронной почты, и заменили их на идентификатор маркера. Это позволяет таким поставщикам услуг
              предоставлять услуги аналитики или сравнивать Ваши данные с общедоступными базами данных (в том числе с
              контактными данными и социальной информацией из других источников). Мы делаем это для более эффективного и
              индивидуализированного взаимодействия с Вами.
            </li>
            <li><u>Передача данных для использования функций социальных сетей:</u>
              функции социальных сетей в Услугах (например, кнопка "Мне нравится" в Facebook) позволяют сторонним
              поставщикам социальных сетей получать определенную информацию, например IP-адрес и страницы, посещаемые
              при использовании раздела "Услуги", а также устанавливать файлы cookie для обеспечения работы функций.
              Использование данных функций регулируется политикой конфиденциальности сторонней компании.
            </li>
            <li><u>Передача данных для проведения промоакций и опросов:</u>
              Мы можем передавать Ваши данные, необходимые для проведения, маркетинга или спонсирования промоакций и
              опросов, в которых Вы захотите участвовать, в соответствии с действующим законодательством (например, для
              предоставления списков победителей и заполнения заявок) или в соответствии с правилами промоакции или
              опроса.
            </li>
            <li><u>Передача данных в рекламных целях:</u>
              Если мы решим использовать модель получения дохода на базе рекламы в будущем, мы можем использовать и
              передавать определенные Системные данные и Данные об использовании сторонним рекламным агентам и сетям для
              формирования представления о демографической ситуации и предпочтениях наших пользователей. Мы также можем
              разрешить рекламным агентам собирать Системные данные с помощью Инструментов сбора данных (как указано в
              Разделе 2.1), использовать эти данные, чтобы предложить Вам целевую рекламу для персонализации Вашего
              пользовательского интерфейса (посредством поведенческой рекламы) и в целях проведения веб-аналитики. Кроме
              того, рекламные агенты могут передавать нам данные о Вас. Чтобы получить более подробную информацию или
              отказаться от получения поведенческой рекламы от участников сети, см. раздел 6.1 (Ваш выбор относительно
              использования Ваших данных) ниже. Обратите внимание, что если Вы откажетесь от поведенческой рекламы, Вы
              по-прежнему будете получать рекламные объявления общего характера.
            </li>
            <li><u>Передача данных для обеспечения безопасности и нормативно-правового соответствия:</u>
              мы можем раскрывать Ваши данные третьим сторонам по нашему усмотрению, если мы добросовестно полагаем, что
              раскрытие информации:
            </li>
            <ul>
              <li type="circle">
                разрешено или требуется по закону;
              </li>
              <li type="circle">
                запрашивается в связи с судебным, государственным или юридическим дознанием, приказом или
                разбирательством;
              </li>
              <li type="circle">
                разумно необходимо в соответствии с действием судебной повестки, предписания или иного действительного
                запроса;
              </li>
              <li type="circle">
                разумно необходимо для соблюдения наших Условий пользования, Политики конфиденциальности или любых
                других юридических соглашений;
              </li>
              <li type="circle">
                требуется для выявления, пресечения или реагирования на мошенничество, злоупотребления, неправомерное
                использование, возможные нарушения закона (или норм и правил), вопросов безопасности или технических
                вопросов; или
              </li>
              <li type="circle">
                по нашему усмотрению требуется или разумно необходимо для защиты от неминуемой угрозы нарушения прав,
                причинения ущерба собственности или безопасности компании STARTUP CHOIKHONA, наших пользователей,
                сотрудников,
                представителей общественности или наших Услуг.
              </li>
              <li type="circle">
                Мы также можем раскрывать Ваши данные нашим аудиторам или юристам-консультантам в соответствии с нашими
                обязательствами и правами по раскрытию информации в рамках данной Политики конфиденциальности.
              </li>
            </ul>
            <li><u>Передача данных во время смены контроля:</u>
              если компания STARTUP CHOIKHONA подвергается преобразованиям, таким как слияния, поглощения, корпоративное
              разделение
              или ликвидация (включая банкротство), продает часть своих активов или все свои активы, мы можем
              передавать, разглашать и делиться Вашими данными с организацией-преемником в момент подобного
              преобразования или его ожидания (в том числе в ходе экспертной оценки).
            </li>
            <li><u>Передача данных после их обобщения/обезличивания:</u>
              Мы можем разглашать и использовать совокупную или обезличенную информацию в любых целях.
            </li>
            <li><u>Передача данных с Вашего разрешения:</u>
              С Вашего согласия мы можем обмениваться данными с третьими сторонами, на которых не распространяется
              данная Политика конфиденциальности.
            </li>
          </ul>
          <p />
          <h2 id="section5">
            5. Безопасность
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Мы используем надлежащие меры безопасности в зависимости от типа и уязвимости хранимых данных. Как и в
              случае с любой системой с выходом в Интернет, всегда существует риск несанкционированного доступа, поэтому
              так важно защищать свой пароль и обращаться к нам, если Вы подозреваете, что кто-то получил
              несанкционированный доступ к Вашей учетной записи.
            </div>
          </div>
          <p />
          <p>
            Компания STARTUP CHOIKHONA принимает соответствующие меры безопасности для защиты от несанкционированного
            доступа,
            изменения, а также раскрытия или уничтожения Ваших персональных данных, которые мы собираем и храним. Эти
            меры зависят от типа и уязвимости данных. При этом, к сожалению, ни одна система не может быть защищена на
            100%, и мы не можем гарантировать полное отсутствие несанкционированного доступа третьих лиц к обмену
            сообщениями между Вами и компанией STARTUP CHOIKHONA, разделу «Услуги» или любой информации, предоставляемой
            нам в связи
            с данными, которые мы собираем через раздел «Услуги». Ваш пароль является важной частью нашей системы
            безопасности, и Вы несете ответственность за его защиту. Вы не должны передавать свой пароль любой третьей
            стороне, и если Вы считаете, что Ваш пароль или учетная запись были взломаны, Вы должны немедленно изменить
            пароль и сообщить о своих подозрениях в нашу <a href="https://edu.startupchoikhona.tj/backend">службу поддержки</a>.
          </p>
          <h2 id="section6">
            6. Ваши права
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              У Вас имеются определенные права в отношении использования Ваших данных, в том числе возможность
              отказаться от получения рекламных писем, файлов cookie и сбора Ваших данных некоторыми третьими сторонами.
              Вы можете обновить или прекратить действие Вашей учетной записи через платформу наших Услуг, а также
              можете связаться с нами для осуществления личных прав касательно Ваших персональных данных. Родители,
              которые считают, что мы непреднамеренно получили персональные данные их несовершеннолетнего ребенка,
              должны обратиться к нам для удаления такой информации.
            </div>
          </div>
          <p />
          <h3>
            6.1. Ваш выбор относительно использования Ваших данных
          </h3>
          <p>
            При наличии вопросов относительно использования файлов cookie свяжитесь с нами по адресу <a
              id=""
              href="mailto:privacy@training.tj"
              class=""
            >privacy@training.tj</a>.
          </p>
          <h3>
            6.2. Доступ, обновление и удаление Ваших персональных данных
          </h3>
          <p>
            Вы можете получить доступ к Вашим персональным данным, собранным и хранящимся в STARTUP CHOIKHONA, а также
            обновить их
            следующим образом:
          </p>
          <ul>
            <li>
              Для обновления данных, непосредственно предоставленных Вами, войдите в свою учетную запись и обновите Вашу
              учетную запись в любое время.
            </li>
            <li>
              Чтобы прекратить действие Вашей учетной записи:
              <ul>
                <li type="circle">
                  Если Вы студент, перейдите на страницу настроек Вашего профиля и выполните действия, подробно
                  описанные <a href="https://www.training.tj">по ссылке</a> (на английском языке).
                </li>
                <li type="circle">
                  Если вы являетесь преподавателем, выполните действия, описанные <a
                    href="https://www.training.tj"
                  >по ссылке</a> (на английском языке).
                </li>
                <li type="circle">
                  Если у вас возникли трудности с прекращением действия Вашей учетной записи, обратитесь в нашу <a
                    href="https://www.training.tj"
                  >службу поддержки</a>.
                </li>
                <li type="circle">
                  Обратите внимание, что даже после удаления Вашей учетной записи некоторые или все Ваши данные могут
                  быть по-прежнему видны другим пользователям, в том числе, без ограничений, любые данные, которые были
                  (а) скопированы, сохранены или распространены другими пользователями (в том числе в комментариях к
                  материалам); (б) размещены или распространены Вами или другими лицами (в том числе Ваши общедоступные
                  материалы); или (в) размещены на сторонней платформе. Даже после удаления вашей учетной записи мы
                  сохраняем Ваши данные до тех пор, пока у нас есть законные основания делать это (а также в
                  соответствии с применимым законодательством), чтобы оказывать помощь в отношении каких-либо законных
                  обязательств, урегулировать споры и обеспечивать выполнение наших соглашений. Мы можем сохранять и
                  раскрывать такие данные в соответствии с данной Политикой конфиденциальности после того, как Ваша
                  учетная запись была удалена.
                </li>
              </ul>
            </li>
            <li>
              Чтобы отправить запрос на доступ, исправление или удаление Ваших персональных данных, воспользуйтесь нашей
              онлайн-формой по <a
                href="https://edu.startupchoikhona.tj/backend"
              >ссылке</a>. Вы также можете направить запрос по электронной почте <a
                id=""
                href="mailto:privacy@training.tj"
                class=""
              >privacy@training.tj</a> или написать нам по адресу: STARTUP CHOIKHONA, Attn: Privacy/Legal Team, 600
              Harrison Street, 3rd
              floor, San Francisco CA 94107. Ответ будет предоставлен в течение 30 дней. Для Вашей безопасности мы можем
              потребовать, чтобы запрос был направлен через адрес электронной почты, связанный с Вашей учетной записью,
              и нам может потребоваться проверить Вашу личность, прежде чем выполнить Ваш запрос. Обратите внимание, что
              мы сохраняем определенные данные, если мы имеем законное основание для этого, в том числе для
              обязательного ведения учета и для завершения транзакций.
            </li>
          </ul>
          <h3>
            6.3. Наша политика в отношении детей
          </h3>
          <p>
            Мы учитываем право детей на конфиденциальность и рекомендуем родителям и опекунам принимать активное участие
            в действиях и интересах их детей в интернете. Лица, не достигшие возраста 18 лет, но достигшие достаточного
            возраста для использования интернет-услуг в стране проживания (например, 13 лет в РТ),
            не могут создать учетную запись, но могут обратиться к родителям или опекунам, чтобы те создали учетную
            запись для получения доступа к соответствующим материалам. Лица, не достигшие достаточного возраста для
            использования интернет-услуг, не имеют права пользоваться Услугами. Если мы обнаружим, что были собраны
            персональные данные ребенка, не достигшего достаточного возраста, мы предпримем соответствующие действия для
            их удаления.
          </p>
          <p>
            Родители, которые считают, что компания STARTUP CHOIKHONA могла получить персональные данные их ребенка, не
            достигшего
            указанного возраста, вправе направить запрос на удаление таких данных по адресу <a
              id=""
              href="mailto:privacy@training.tj"
              class=""
            >privacy@udemy.com</a>.
          </p>
          <h2 id="section7">
            7. Правила, применимые к определенным юрисдикциям
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Если Вы являетесь резидентом штата Калифорнии, Вы можете воспользоваться определенными правами на доступ и
              удаление информации, а также на информацию о том, кому передаются ваши данные. Если вы являетесь
              резидентом Австралии, у Вас есть право подать официальную жалобу в соответствующий государственный орган.
              Обращаем внимание пользователей за пределами РТ на то, что мы передаем данные в РТ и на другие
              территории, не входящие в Европейскую экономическую зону.
            </div>
          </div>
          <p />
          <h2 id="section8">
            8. Обновления и контактные данные
          </h2>
          <p />
          <div
            role="alert"
            class="alert alert-info"
          >
            <div class="text-black-50 p-1">
              Если мы внесем существенные изменения в эту политику, мы уведомим об этом пользователей по электронной
              почте, с помощью уведомления внутри продукта или другого механизма в соответствии с требованиями
              законодательства. Изменения вступают в силу в день их публикации. При возникновении каких-либо вопросов,
              опасений или несогласия свяжитесь с нами по электронной почте или по почте.
            </div>
          </div>
          <p />
          <h3>
            8.1. Внесение изменений в Политику конфиденциальности
          </h3>
          <p>
            Время от времени мы можем вносить изменения в данную Политику конфиденциальности. При внесении нами любых
            серьезных изменений мы уведомим Вас по электронной почте, с помощью оповещения, опубликованного в разделе
            "Услуги", или таким способом, как это требуется по закону. Мы можем также привести краткое описание основных
            изменений. Если не указано иное, изменения вступают в силу в день их публикации.
          </p>
          <p>
            В соответствии с применимым законодательством, если Вы продолжаете пользоваться Услугами после даты
            вступления в силу любых изменений, то Ваш доступ и/или использование будет рассматриваться как принятие и
            согласие соблюдать измененную Политику конфиденциальности. Измененная Политика конфиденциальности заменяет
            собой все Политики конфиденциальности, выпущенные ранее.
          </p>
          <h3>
            8.2. Толкование
          </h3>
          <p>
            Любые термины с заглавной буквы, не определенные в этой политике, имеют определения, приведенные в <a
              href="/terms/"
            >Условиях использования</a> STARTUP CHOIKHONA. Любая версия этой Политики конфиденциальности на другом языке,
            кроме
            английского, приведена исключительно для удобства. В случае расхождений с версией на другом языке Вы
            соглашаетесь с тем, что версия на английском языке будет иметь преимущественную силу.
          </p>
          <h3>
            8.3. Вопросы
          </h3>
          <p>
            Если у Вас есть вопросы, опасения или споры, связанные с нашей Политикой конфиденциальности, свяжитесь с
            нашей командой по вопросам конфиденциальности (в том числе с нашим специалистом по защите данных) по адресу
            <a
              id=""
              href="mailto:privacy@udemy.com"
              class=""
            >privacy@udemy.com</a>. Вы также можете отправить нам
            письмо по почтовому адресу STARTUP CHOIKHONA, Attn: Legal, 600 Harrison Street, 3rd Floor, San Francisco, CA
            94107.
          </p>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormRadioGroup,
  BFormFile,
  BFormTextarea,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BOverlay,
  BIcon,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  required, email, between, max,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ru from 'vee-validate/dist/locale/ru.json'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import router from '@/router'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormFile,
    BFormTextarea,
    BInputGroupPrepend,
    BModal,
    BOverlay,
    BIcon,
    BProgress,
    // validations
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showOverlay: false,
      uploadPercentage: 0,
      currentYear: new Date().getFullYear(),
      userInfo: {
        fio: '',
        phone: '',
        userEmail: '',
        birthYear: '',
        birthYearOptions: [],
        mainActivity: '',
        mainActivityOptions: [
          {
            value: 'school',
            label: 'школьник',
          },
          {
            value: 'student',
            label: 'студент',
          },
          {
            value: 'workForHire',
            label: 'работаю по найму',
          },
          {
            value: 'ownBusiness',
            label: 'свой бизнес',
          },
        ],
        sex: '',
        sexOptions: [
          {
            text: 'Мужчина',
            value: 'male',
          },
          {
            text: 'Женщина',
            value: 'female',
          },
        ],
        address: '',
        statusOrganization: '',
        statusOrganizationOptions: [
          {
            text: 'Да',
            value: 'Yes',
          },
          {
            text: 'Нет',
            value: 'No',
          },
        ],
      },
      company: {
        name: '',
        site: '',
        about: '',
        resolveProblems: '',
        presentation: null,
        typeWork: '',
        typeWorkOptions: [],
        stage: '',
        stageOptions: [
          {
            value: 'idea',
            label: 'Идея',
          },
          {
            value: 'beginningBusiness',
            label: 'Начинающий бизнес',
          },
          {
            value: 'workingBusiness',
            label: 'Работающий бизнес',
          },
          {
            value: 'onMarketMoreThanAYear',
            label: 'На рынке более года',
          },
          {
            value: 'onMarketMoreThanThreeYears',
            label: 'На рынке более трех лет',
          },
        ],
        directionActivity: '',
        directionActivityOptions: [
          {
            text: 'Коммерческая',
            value: 'commercial',
          },
          {
            text: 'Некоммерческая',
            value: 'noncommercial',
          },
        ],
        statusRegister: '',
        statusRegisterOptions: [
          {
            text: 'Да',
            value: 'yes',
          },
          {
            text: 'Нет',
            value: 'no',
          },
        ],
        sumInvest: {
          from: '',
          to: '',
        },
        sumInvestFor: '',
        achievement: '',
        statusTechnical: '',
        statusTechnicalOptions: [
          {
            text: 'Да',
            value: 'yes',
          },
          {
            text: 'Нет',
            value: 'no',
          },
        ],
        statusFind: '',
        statusFindOptions: [
          {
            text: 'Да',
            value: 'yes',
          },
          {
            text: 'Нет',
            value: 'no',
          },
        ],
        statusWorkStartup: '',
        statusWorkStartupOptions: [
          {
            text: 'Да',
            value: 'yes',
          },
          {
            text: 'Нет',
            value: 'no',
          },
        ],
      },
      signinInfo: {
        password: '',
        confirmPassword: '',
        status: false,
      },
      sideImg: require('@/assets/images/landing/bg.svg'),
      // validation
      required,
      email,
      between,
      max,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/landing/bg.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      buildFormData,
    }
  },
  created() {
    localize('ru', ru)
    // eslint-disable-next-line no-plusplus
    for (let y = this.currentYear; y >= this.currentYear - 72; y--) {
      this.userInfo.birthYearOptions.push(
        {
          value: y,
          label: y,
        },
      )
    }
    this.getTypeWorks()
  },
  methods: {
    onShownOverlay() {
      const config = {
        onUploadProgress: progressEvent => console.log(progressEvent.loaded),
      }
      const userInfoResponse = this.userInfo
      delete userInfoResponse.birthYearOptions
      delete userInfoResponse.mainActivityOptions
      delete userInfoResponse.sexOptions
      delete userInfoResponse.statusOrganizationOptions

      const companyResponse = this.company
      delete companyResponse.typeWorkOptions
      delete companyResponse.stageOptions
      delete companyResponse.directionActivityOptions
      delete companyResponse.statusRegisterOptions
      delete companyResponse.statusTechnicalOptions
      delete companyResponse.statusFindOptions
      delete companyResponse.statusWorkStartupOptions

      const dataResponse = {
        userInfo: this.userInfo,
        company: this.company,
        signinInfo: this.signinInfo,
      }
      const formData = new FormData()

      this.buildFormData(formData, dataResponse)
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/register', formData,
        {
          onUploadProgress: progressEvent => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          },
        })
        .then(response => {
          const roleTranslate = {
            admin: 'Администратор',
            student: 'Студент',
            teacher: 'Ментор',
          }
          const userData = response.data.data
          useJwt.setToken(userData.access_token)
          useJwt.setRefreshToken(userData.refresh_token)
          localStorage.setItem('userData', JSON.stringify(userData))
          // Update when logged in
          this.$ability.update(userData.ability)
          if (Object.keys(this.$router.currentRoute.query).length > 0 && this.$router.currentRoute.query.redirectTo.href) {
            window.location = this.$router.currentRoute.query.redirectTo.href
          }
          if (Object.keys(this.$router.currentRoute.query).length > 0 && this.$router.currentRoute.query.redirectTo) {
            window.location = this.$router.currentRoute.query.redirectTo
          }
          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          if (userData.roles.length > 0) {
            const role = userData.roles[0]
            this.$router.replace(getHomeRouteForLoggedInUser(role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.data.message,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Вы успешно вошли в систему как ${roleTranslate[userData.roles[0]]}. Теперь вы можете начать работу!`,
                  },
                })
              })
          }
        })
        .catch(error => {
          this.showOverlay = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'CloseIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    onHiddenOverlay() {
    },
    getTypeWorks() {
      this.$http
        .get('https://edu.startupchoikhona.tj/backend/api/type-work', {
          params: {},
        })
        .then(response => {
          this.company.typeWorkOptions = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение сферы деятельносты',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userInfoForm.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationCompanyDetailsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyDetailsForm.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationSignInDetailsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.signInDetailsForm.validate()
          .then(success => {
            if (success) {
              if (this.signinInfo.status === false) {
                reject()
              }
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    formSubmitted() {
      this.showOverlay = true
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
